<template>
  <div v-if="!loading" class="pa-0">
    <v-card>
      <v-simple-table fixed-header dense>
        <thead>
          <tr>
            <th colspan="3" style="position:sticky; left:0;z-index: 11000;vertical-align: bottom;font-size: large;font-weight: bold" class="stick text-center">EVENTOS</th>
            <th style="font-size: large;font-weight: bold;" class="stick text-center diagonal-header">ADULTOS</th>
            <th style="font-size: large;font-weight: bold;max-width: 48px !important;" class="stick text-center diagonal-header"><div class="diagonal-text ps-1">CLÁSICA</div></th>
            <th style="font-size: large;font-weight: bold;max-width: 48px !important;" class="stick text-center diagonal-header"><div class="diagonal-text ps-1">COCTEL</div></th>
            <th style="font-size: large;font-weight: bold;max-width: 48px !important;" class="stick text-center diagonal-header"><div class="diagonal-text ps-1">PB PTES</div></th>
            <template v-for="(f,i) in productos">
              <th v-if="i == 0 || f.superFamilia != productos[i-1].superFamilia" class="stick diagonal-header" style="padding: 0 !important;"><div class="diagonal-familia d-flex align-center ps-3" style="font-size: xx-large;font-weight: bold;color: var(--v-info-base);text-decoration: underline;">{{ superFamilias.find(fam => fam.id == f.superFamilia).nombre }}</div></th>
              <th v-if="i == 0 || f.idFamilia != productos[i-1].idFamilia" class="stick diagonal-header" style="padding: 0 !important;"><div class="diagonal-familia d-flex align-center ps-1" style="font-size: large;font-weight: bold;color: var(--v-primary-base);max-width: 30px !important">{{ familias.find(fam => fam.idFamilia == f.idFamilia).nombreFamilia }}</div></th>
              <th style="" class="stick diagonal-header"><div class="diagonal-text ps-1">{{ f.nombreProducto }}</div></th>
            </template>
          </tr>
          <tr>
            <th :style="'background: ' + ($vuetify.theme.dark ? 'rgb(30, 30, 30)' : 'white') + ';position:sticky; left:0;z-index: 11000;border-right: 1px solid lightgray;border-bottom: 1px solid lightgray;min-width: 200px !important;'" colspan="3" class="text-center">TOTAL</th>
            <th style="border-right: 1px solid lightgray;border-bottom: 1px solid lightgray;">{{ huecos.filter(hue => !!hue.tipo).reduce((acc,sum) => acc + parseFloat(sum.adultos || 0),0) }}</th>
            <th style="border-right: 1px solid lightgray;border-bottom: 1px solid lightgray;" class="text-center">{{ huecos.filter(h => h.tipo == 1).length }}</th>
            <th style="border-right: 1px solid lightgray;border-bottom: 1px solid lightgray;" class="text-center">{{ huecos.filter(h => h.tipo == 2).length }}</th>
            <th style="border-right: 1px solid lightgray;border-bottom: 1px solid lightgray;" class="text-center">{{ huecos.filter(h => !h.tipo).length }}</th>
            <template v-for="(f,i) in productos">
              <th v-if="i == 0 || f.superFamilia != productos[i-1].superFamilia" style="background-color: var(--v-info-base); border-bottom: 1px solid lightgray;border-right: 1px solid lightgray;"></th>
              <th v-if="i == 0 || f.idFamilia != productos[i-1].idFamilia" style="background-color: var(--v-primary-base); border-bottom: 1px solid lightgray;border-right: 1px solid lightgray;"></th>
              <th class="text-center" style="border-right: 1px solid lightgray;border-bottom: 1px solid lightgray;">{{ calculateTotalForProduct(f) }}</th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr v-for="hueco in huecosFiltered" :key="hueco.idEvento">
            <td :style="'background: ' + ($vuetify.theme.dark ? 'rgb(30, 30, 30)' : 'white') + ';position:sticky; left:0;z-index: 1000;min-width: 125px !important; white-space: nowrap;border-right: 1px solid lightgray;'">{{ new Date(hueco.fecha).toLocaleDateString('esp',{day:'numeric',month:'short', year:'numeric'}).toUpperCase() }}</td>
            <td :style="'background: ' + ($vuetify.theme.dark ? 'rgb(30, 30, 30)' : 'white') + ';position:sticky; left:125px;z-index: 1000;min-width: 75px !important; white-space: nowrap;border-right: 1px solid lightgray;'">{{ hueco.espacio || 'PTE' }}</td>
            <td :style="'background: ' + ($vuetify.theme.dark ? 'rgb(30, 30, 30)' : 'white') + ';position:sticky; left:200px;z-index: 1000;min-width: 75px !important; white-space: nowrap;border-right: 1px solid lightgray;'">{{ hueco.who || '' }}</td>
            <td style="border-right: 1px solid lightgray;"><v-text-field style="width: 100px;" v-model="hueco.adultos" dense hide-details="auto" placeholder="Nº Adultos"></v-text-field></td>
            <td style="border-right: 1px solid lightgray; vertical-align: middle;text-align: center;">
              <v-checkbox :value="1" style="max-height: 48px !important; max-width: 48px;" hide-details="auto" class="mx-auto" v-model="hueco.tipo"/>
            </td>
            <td style="border-right: 1px solid lightgray; vertical-align: middle;text-align: center;">
              <v-checkbox :value="2" style="max-height: 48px !important; max-width: 48px;" hide-details="auto" class="mx-auto" v-model="hueco.tipo"/>
            </td>
            <td style="border-right: 1px solid lightgray; vertical-align: middle;text-align: center;">{{ +!hueco.tipo }}</td>
            <template v-for="(f,i) in productos">
              <td v-if="i == 0 || f.superFamilia != productos[i-1].superFamilia" style="background-color: var(--v-info-base); border-bottom: 0;border-right: 1px solid lightgray;border-left: 1px solid lightgray;"></td>
              <td v-if="i == 0 || f.idFamilia != productos[i-1].idFamilia" style="background-color: var(--v-primary-base); border-bottom: 0;border-right: 1px solid lightgray;border-left: 1px solid lightgray;"></td>
              <!-- <td v-if="i == 0 || f.idFamilia != productos[i-1].idFamilia" :style="'background-color: ' + ($vuetify.theme.dark ? 'white' : 'black')"></td> -->
              <td class="pa-0 text-center" v-if="includesTipo(hueco.tipo,f.cartas)" style="border-right: 1px solid lightgray;" :class="$vuetify.theme.dark ? 'itemDark' : 'item'" @click="toggleItem(hueco,f)" @contextmenu.prevent="f.writable ? addNote(hueco,f.key) : ''" :title="hueco.notas[f.key]" :style="'border-right: 1px solid lightgray; ' + ['','background-color: var(--v-success-base)','background-color: var(--v-warning-base)'][hueco.productos[f.key]]"><v-icon v-if="hueco.notas[f.key]">mdi-account-voice</v-icon></td>
              <td class="pa-0 text-center" v-else :style="'border-right: 1px solid lightgray; ' + ($vuetify.theme.dark ? 'background-color:gray' : 'background-color: black')"></td>
              <!-- <td v-else :style="'border-right: 1px solid lightgray; ' + $vuetify.theme.dark ? 'background-color:gray' : 'background-color: black'"><v-icon color="error">mdi-close</v-icon></td> -->
            </template>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
    <ConfirmDialog ref="confirm" />
    <AddNote ref="addNote" />
    <div style="max-width: fit-content;position: fixed;right:20px; bottom: 20px;z-index:100000" bottom class="d-flex mt-2 ms-auto">
      <v-btn color="primary" fab @click="exportar" large class="me-4">
        <v-icon>mdi-microsoft-excel</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" fab @click="savePlan" large>
        <v-icon>mdi-floppy</v-icon>
      </v-btn>
    </div>
  </div>
  <v-container v-else>
    <div style="width: 100%;height: 90vh;" class="d-flex align-center justify-center">
      <v-progress-circular
        size="250"
        indeterminate
        color="primary"
      >
        <h2>Cargando</h2>
      </v-progress-circular>
    </div>
  </v-container>
</template>

<script>

import FileSaver from 'file-saver';
import * as ExcelJS from "exceljs";

export default {
  props:{
    date: String
  },
  components: {
    // Evento: () => import("../components/Evento.vue"),
    AddNote: () => import("../components/AddNote.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  computed:{
    huecosFiltered(){
      return this.huecos
    },
  },
  data() {
    return {
      loading: true,
      superFamilias:[
        { id : 0, nombre:"APERITIVO" },
        { id : 1, nombre:"MENÚ" },
        { id : 2, nombre:"BODEGA" },
        { id : 3, nombre:"RECENA" },
      ],
      cartas:[
        { id : 1, nombre:"CLÁSICA" },
        { id : 2, nombre:"CÓCTEL" },
      ],
      familias:[
        {idFamilia: 1, nombreFamilia: "RECEPCIÓN" },
        {idFamilia: 2, nombreFamilia: "BUFFETS"},
        {idFamilia: 3, nombreFamilia: "TEMÁTICOS" },
        {idFamilia: 4, nombreFamilia: "ESPECTÁCULO" },
        {idFamilia: 5, nombreFamilia: "DELICATESSEN" },
        // {idFamilia: 6, nombreFamilia: "BODEGA" },
        {idFamilia: 7, nombreFamilia: "UN RESPIRO" },
        {idFamilia: 8, nombreFamilia: "PARA ACABAR" },
        {idFamilia: 11, nombreFamilia: "PARA EMPEZAR"},
        // {idFamilia: 10, nombreFamilia: "BAR" },
        {idFamilia: 12, nombreFamilia: "PRIMER PLATO" },
        {idFamilia: 13, nombreFamilia: "SORBETES"},
        {idFamilia: 14, nombreFamilia: "CARNES" },
        {idFamilia: 15, nombreFamilia: "POSTRES"},
        {idFamilia: 9, nombreFamilia: "MOMENTO DULCE" },
        // {idFamilia: 16, nombreFamilia: "VINO BLANCO" },
        // {idFamilia: 17, nombreFamilia: "VINO TINTO" },
        // {idFamilia: 18, nombreFamilia: "CAVA" },
        {idFamilia: 19, nombreFamilia: "RECENA" },
        // {idFamilia: 20, nombreFamilia: "FIN DE FIESTA"},
        // {idFamilia: 21, nombreFamilia: "CARTELERÍA" },
        // {idFamilia: 22, nombreFamilia: "DECORACIÓN ESPACIOS" },
      ],
      productos:[
        // CASA
        { cartas: [1,2], idFamilia: 1, superFamilia:0,key:"cavaYLimonada", nombreProducto: "CAVA y LIMONADA"},
        { cartas: [1,2], idFamilia: 1, superFamilia:0,key:"recepcionOtros", nombreProducto: "OTROS", writable: true},
        { cartas: [1,2], idFamilia: 2, superFamilia:0,key:"jamoncito", nombreProducto: "JAMONCITO"},
        { cartas: [1,2], idFamilia: 2, superFamilia:0,key:"laCharcuteria", nombreProducto: "LA CHARCUTERIA"},
        { cartas: [1,2], idFamilia: 2, superFamilia:0,key:"laQueseria", nombreProducto: "LA QUESERÍA"},
        { cartas: [1,2], idFamilia: 2, superFamilia:0,key:"japones", nombreProducto: "JAPONÉS"},
        { cartas: [1,2], idFamilia: 2, superFamilia:0,key:"pato", nombreProducto: "PATO"},
        { cartas: [1,2], idFamilia: 2, superFamilia:0,key:"vermuteria", nombreProducto: "VERMUTERÍA"},
        { cartas: [1,2], idFamilia: 2, superFamilia:0,key:"otrosBuffets", nombreProducto: "OTROS", writable: true},
        { cartas: [1,2], idFamilia: 3, superFamilia:0,key:"arroces", nombreProducto: "ARROCES"},
        { cartas: [1,2], idFamilia: 3, superFamilia:0,key:"espetos", nombreProducto: "ESPETOS"},
        { cartas: [1,2], idFamilia: 3, superFamilia:0,key:"takeAway", nombreProducto: "TAKE AWAY"},
        { cartas: [1,2], idFamilia: 3, superFamilia:0,key:"km0", nombreProducto: "KM0"},
        { cartas: [1,2], idFamilia: 3, superFamilia:0,key:"tacosBar", nombreProducto: "TACOS BAR"},
        { cartas: [1,2], idFamilia: 3, superFamilia:0,key:"cocinaEnDirecto", nombreProducto: "COCINA DIRECTO"},
        { cartas: [1,2], idFamilia: 3, superFamilia:0,key:"otrosTematicos", nombreProducto: "OTROS", writable: true},
        { cartas: [1,2], idFamilia: 4, superFamilia:0,key:"pulpeiro",nombreProducto: "PULPEIRO"},
        { cartas: [1,2], idFamilia: 4, superFamilia:0,key:"marinero",nombreProducto:"MARINERO"},
        { cartas: [1,2], idFamilia: 4, superFamilia:0,key:"ahumados",nombreProducto:"AHUMADOS"},
        { cartas: [1,2], idFamilia: 4, superFamilia:0,key:"puroShow",nombreProducto:"PURO SHOW - PURA VACA"},
        { cartas: [1,2], idFamilia: 4, superFamilia:0,key:"otrosEspectaculo",nombreProducto:"OTROS", writable:true},
        { cartas: [1,2], idFamilia: 5, superFamilia:0,key:"capuccino",nombreProducto: "CAPUCCINO"},
        { cartas: [1,2], idFamilia: 5, superFamilia:0,key:"air",nombreProducto: "AIR"},
        { cartas: [1,2], idFamilia: 5, superFamilia:0,key:"ssam",nombreProducto: "SSÄM"},
        { cartas: [1,2], idFamilia: 5, superFamilia:0,key:"croquetas",nombreProducto: "CROQUETAS"},
        { cartas: [1,2], idFamilia: 5, superFamilia:0,key:"coca",nombreProducto: "COCA"},
        { cartas: [1,2], idFamilia: 5, superFamilia:0,key:"empanadillas",nombreProducto: "EMPANADILLAS"},
        { cartas: [1,2], idFamilia: 5, superFamilia:0,key:"bikini",nombreProducto: "BIKINI"},
        { cartas: [1,2], idFamilia: 5, superFamilia:0,key:"arroz",nombreProducto: "ARROZ"},
        { cartas: [1,2], idFamilia: 5, superFamilia:0,key:"tataki",nombreProducto: "TATAKI"},
        { cartas: [1,2], idFamilia: 5, superFamilia:0,key:"miniCanelon",nombreProducto: "MINI CANELÓN"},
        { cartas: [1,2], idFamilia: 5, superFamilia:0,key:"borraja",nombreProducto: "BORRAJA"},
        { cartas: [1,2], idFamilia: 5, superFamilia:0,key:"bao",nombreProducto: "BAO"},
        { cartas: [1,2], idFamilia: 5, superFamilia:0,key:"huevoPache",nombreProducto: "HUEVO PACHÉ"},
        { cartas: [1,2], idFamilia: 5, superFamilia:0,key:"mcfarre",nombreProducto: "MCFARRÉ"},
        { cartas: [1,2], idFamilia: 5, superFamilia:0,key:"mini",nombreProducto: "MINI CROISSANT"},
        { cartas: [1,2], idFamilia: 5, superFamilia:0,key:"cracker",nombreProducto: "CRACKER"},
        { cartas: [1,2], idFamilia: 5, superFamilia:0,key:"crujiente",nombreProducto: "CRUJIENTE"},
        { cartas: [1,2], idFamilia: 5, superFamilia:0,key:"zamburinas",nombreProducto: "ZAMBURIÑAS"},
        { cartas: [1,2], idFamilia: 5, superFamilia:0,key:"tonaktzu",nombreProducto: "TONAKTZU"},
        { cartas: [1,2], idFamilia: 5, superFamilia:0,key:"brioche",nombreProducto: "BRIOCHE"},
        { cartas: [1,2], idFamilia: 5, superFamilia:0,key:"tostaDeMantequilla",nombreProducto: "TOSTA MTQLLA"},
        { cartas: [1,2], idFamilia: 5, superFamilia:0,key:"vieira",nombreProducto: "VIEIRA"},
        { cartas: [1,2], idFamilia: 5, superFamilia:0,key:"milHojas",nombreProducto: "MIL HOJAS DE FOIE"},
        { cartas: [1,2], idFamilia: 5, superFamilia:0,key:"otrosDelicatessen",nombreProducto: "OTROS", writable:true},
        // { cartas: [1], idFamilia: 6, superFamilia:0,key:"vinosBlancoTinto", nombreProducto: "VINOS BLANCO Y TINTO"},
        { cartas: [2], idFamilia: 7, superFamilia:0,key:"sorbeteGewurztaminer", nombreProducto: "SBT GEWÜRZTAMINER"},
        { cartas: [2], idFamilia: 7, superFamilia:0,key:"otrosRespiro", nombreProducto: "OTROS", writable:true},
        { cartas: [2], idFamilia: 8, superFamilia:0,key:"entrecotteDeTernera", nombreProducto: "ENTRECOTTE TERNERA"},
        { cartas: [2], idFamilia: 8, superFamilia:0,key:"otrosParaAcabar", nombreProducto: "OTROS", writable:true},
        // { cartas: [1,2], idFamilia: 10, superFamilia:0,key:"aguasRefrescosCervezasVermut",nombreProducto: "AGUAS, REFRESCOS, CERVEZAS, VERMUT"},
        { cartas: [1], idFamilia: 11, superFamilia:1,key:"salmorejo", nombreProducto: "SALMOREJO"},
        { cartas: [1], idFamilia: 11, superFamilia:1,key:"sopitaFria",nombreProducto: "SOPITA FRÍA"},
        { cartas: [1], idFamilia: 11, superFamilia:1,key:"tartarDeAtun",nombreProducto: "TARTAR ATÚN"},
        { cartas: [1], idFamilia: 11, superFamilia:1,key:"otrosMenu",nombreProducto: "OTROS",writable:true},
        { cartas: [1], idFamilia: 12, superFamilia:1,key:"ensalada", nombreProducto: "ENSALADA"},
        { cartas: [1], idFamilia: 12, superFamilia:1,key:"canneloni", nombreProducto: "CANNELONI"},
        { cartas: [1], idFamilia: 12, superFamilia:1,key:"ravioli", nombreProducto: "RAVIOLLI"},
        { cartas: [1], idFamilia: 12, superFamilia:1,key:"canelonDeRape", nombreProducto: "CANELÓN RAPE"},
        { cartas: [1], idFamilia: 12, superFamilia:1,key:"pulpoBraseado", nombreProducto: "PULPO BRASEADO"},
        { cartas: [1], idFamilia: 12, superFamilia:1,key:"merluza", nombreProducto: "MERLUZA"},
        { cartas: [1], idFamilia: 12, superFamilia:1,key:"bacalao", nombreProducto: "BACALAO"},
        { cartas: [1], idFamilia: 12, superFamilia:1,key:"lubina", nombreProducto: "LUBINA"},
        { cartas: [1], idFamilia: 12, superFamilia:1,key:"rodaballo", nombreProducto: "RODABALLO"},
        { cartas: [1], idFamilia: 12, superFamilia:1,key:"ensaladaMedioBogavante", nombreProducto: "ENS. 1/2 BOGAV."},
        { cartas: [1], idFamilia: 12, superFamilia:1,key:"ensaladaBogavante", nombreProducto: "ENS. BOGAV."},
        { cartas: [1], idFamilia: 12, superFamilia:1,key:"bogavante", nombreProducto: "BOGAVANTE"},
        { cartas: [1], idFamilia: 12, superFamilia:1,key:"langostaImperial", nombreProducto: "LANGOSTA IMPERIAL"},
        { cartas: [1], idFamilia: 12, superFamilia:1,key:"otrosPrimero", nombreProducto: "OTROS", writable: true},
        { cartas: [1], idFamilia: 13, superFamilia:1,key:"orangeFantasy", nombreProducto: "ORANGE FANTASY"},
        { cartas: [1], idFamilia: 13, superFamilia:1,key:"greenLove", nombreProducto: "GREEN LOVE"},
        { cartas: [1], idFamilia: 13, superFamilia:1,key:"tropicPassion", nombreProducto: "TROPIC PASSION"},
        { cartas: [1], idFamilia: 13, superFamilia:1,key:"pinkPrite", nombreProducto: "PINK & PRITE"},
        { cartas: [1], idFamilia: 13, superFamilia:1,key:"fresasMoet", nombreProducto: "FRESAS & MÖET"},
        { cartas: [1], idFamilia: 13, superFamilia:1,key:"gewurztraminer", nombreProducto: "GEWURZTRAMINER"},
        { cartas: [1], idFamilia: 13, superFamilia:1,key:"otrosSorbetes", nombreProducto: "OTROS", writable:true},
        { cartas: [1], idFamilia: 14, superFamilia:1,key:"costillaIberica",nombreProducto: "COSTILLA IBÉRICA"},
        { cartas: [1], idFamilia: 14, superFamilia:1,key:"jarreteDeLechazo",nombreProducto:"JARRETE LECHAZO"},
        { cartas: [1], idFamilia: 14, superFamilia:1,key:"paletillaTernasco", nombreProducto: "PALETILLA TERNASCO"},
        { cartas: [1], idFamilia: 14, superFamilia:1,key:"ternascoGlaseado", nombreProducto: "TERNASCO GLASEADO"},
        { cartas: [1], idFamilia: 14, superFamilia:1,key:"solomilloTerneraReduccion", nombreProducto: "SOLOM. TRNRA"},
        { cartas: [1], idFamilia: 14, superFamilia:1,key:"solomilloTerneraEscalopa", nombreProducto: "SOLOM. TRNRA + FOIE"},
        { cartas: [1], idFamilia: 14, superFamilia:1,key:"lechazoDeCastilla", nombreProducto: "LECHAZO CASTILLA"},
        { cartas: [1], idFamilia: 14, superFamilia:1,key:"paletillaDeCabrito", nombreProducto: "PALETILLA CABRITO"},
        { cartas: [1], idFamilia: 14, superFamilia:1,key:"otrosCarnes", nombreProducto: "OTROS",writable:true},
        { cartas: [1], idFamilia: 15, superFamilia:1,key:"lemonPie",nombreProducto: "LEMON PIE"},
        { cartas: [1], idFamilia: 15, superFamilia:1,key:"brownie",nombreProducto: "BROWNIE"},
        { cartas: [1], idFamilia: 15, superFamilia:1,key:"milhojasCaramelizado", nombreProducto: "MILHOJAS CARAM."},
        { cartas: [1], idFamilia: 15, superFamilia:1,key:"bombonHelado", nombreProducto: "BOMBÓN HELADO"},
        { cartas: [1], idFamilia: 15, superFamilia:1,key:"galaxiaDeChocolate", nombreProducto: "GALAXIA CHOCOLATE"},
        { cartas: [1], idFamilia: 15, superFamilia:1,key:"tiramisu", nombreProducto: "TIRAMISÚ"},
        { cartas: [1], idFamilia: 15, superFamilia:1,key:"sacher", nombreProducto: "SACHER"},
        { cartas: [1], idFamilia: 15, superFamilia:1,key:"otrosPostres", nombreProducto: "OTROS", writable:true},
        // { cartas: [1,2], idFamilia: 16, superFamilia:2,key:"vinasDelVeroMC", nombreProducto: "VIÑAS DEL VERO MACABEO-CHARDONNAY"},
        // { cartas: [1,2], idFamilia: 16, superFamilia:2,key:"lausChardonnay", nombreProducto: "LAUS CHARDONNAY"},
        // { cartas: [1,2], idFamilia: 16, superFamilia:2,key:"pirineos3404", nombreProducto: "PIRINEOS 3404"},
        // { cartas: [1,2], idFamilia: 16, superFamilia:2,key:"lauraLalanne", nombreProducto: "LAURA LALANNE"},
        // { cartas: [1,2], idFamilia: 16, superFamilia:2,key:"enate234Chardonnay", nombreProducto: "ENATE 234 CHARDONNAY"},
        // { cartas: [1,2], idFamilia: 16, superFamilia:2,key:"vinasDelVeroGewurztraminer", nombreProducto: "VIÑAS DEL VERO GEWÜRZTRAMINER"},
        // { cartas: [1,2], idFamilia: 17, superFamilia:2,key:"vinasDelVeroCrianza", nombreProducto: "VIÑAS DEL VERO CRIANZA"},
        // { cartas: [1,2], idFamilia: 17, superFamilia:2,key:"lausCrianza", nombreProducto: "LAUS CRIANZA"},
        // { cartas: [1,2], idFamilia: 17, superFamilia:2,key:"deBerozCrianza", nombreProducto: "DE BEROZ CRIANZA"},
        // { cartas: [1,2], idFamilia: 17, superFamilia:2,key:"lalanneCrianza", nombreProducto: "LALANNE CRIANZA"},
        // { cartas: [1,2], idFamilia: 17, superFamilia:2,key:"enateCrianza", nombreProducto: "ENATE CRIANZA"},
        // { cartas: [1,2], idFamilia: 18, superFamilia:2,key:"cavaReyesAragon", nombreProducto: "CAVA REYES DE ARAGÓN BRUT"},
        // { cartas: [1,2], idFamilia: 18, superFamilia:2,key:"freixenetVintageReservaBrut", nombreProducto: "FREIXENET VINTAGE RESERVA BRUT"},
        // { cartas: [1,2], idFamilia: 18, superFamilia:2,key:"lucreciaLalanneBrut", nombreProducto: "LUCRECIA LALANNE BRUT"},
        // { cartas: [1,2], idFamilia: 18, superFamilia:2,key:"agustiTorelloMataBrutNature", nombreProducto: "AGUSTÍ TORRELLÓ & MATA BRUT NATURE"},
        // { cartas: [1,2], idFamilia: 18, superFamilia:2,key:"cavaJuveCampsResservaDeFamilia", nombreProducto: "CAVA JUVÉ & CAMPS RESERVA DE FAMILIA"},
        { cartas: [2], idFamilia: 9, superFamilia:1,key:"miniPerdiciones", nombreProducto: "MINI PERDICIONES"},
        { cartas: [2], idFamilia: 9, superFamilia:1,key:"otrosMomentoDulce", nombreProducto: "OTROS", writable:true},
        { cartas: [1,2], idFamilia: 19, superFamilia:3,key:"recenaTradicional",nombreProducto: "TRADICIONAL"},
        { cartas: [1,2], idFamilia: 19, superFamilia:3,key:"recenaEspecial",nombreProducto: "ESPECIAL"},
        { cartas: [1,2], idFamilia: 19, superFamilia:3,key:"otrosRecena",nombreProducto: "OTROS",writable:true},
        // { cartas: [1,2], idFamilia: 20, superFamilia:3,key:"licoresDeMesa",nombreProducto: "LICORES DE MESA"},
        // { cartas: [1,2], idFamilia: 20, superFamilia:3,key:"barraLibre",nombreProducto: "BARRA LLIBRE"},
        // { cartas: [1,2], idFamilia: 20, superFamilia:3,key:"otrosLicores",nombreProducto: "OTROS LICORES - DETALLAR"},
        // { cartas: [2], idFamilia: 21, superFamilia:3,key:"modeloMasFarre",nombreProducto: "MODELO MAS FARRÉ"},
        // { cartas: [2], idFamilia: 21, superFamilia:3,key:"tieneLogo",nombreProducto: "PAREJA TIENE LOGO"},
        // { cartas: [2], idFamilia: 21, superFamilia:3,key:"tieneSeating",nombreProducto: "PAREJA APORTA SEATING"},
        // { cartas: [2], idFamilia: 21, superFamilia:3,key:"encargaPareja",nombreProducto: "SE ENCARGA LA PAREJA"},
        // { cartas: [2], idFamilia: 22, superFamilia:3,key:"decoracionFloral",nombreProducto: "DECORACION FLORAL"},
      ],
      huecos: [],
      cambios: [],
    };
  },
  methods: {
    async addNote(hueco,key){
      const newNote = (await this.$refs["addNote"].open(
        "Detallar",
        { color: "primary" },
        hueco.notas[key] || ''
      ));
      if (newNote === false) return
      else if (newNote === '') delete hueco.notas[key]
      else this.$set(hueco.notas, key, newNote)
      this.cambios.push(hueco.idEvento)
      this.$forceUpdate()
    },
    includesTipo(h,t){
      return t.some(t => t == h)
    },
    calculateTotalForProduct(f) {
      let total = 0;

      this.huecos.filter(hue => !!hue.tipo).forEach(h => {
        if (h.productos && h.productos[f.key]) {
          let familyProductsState2Count = 0;

          const familyProducts = this.productos.filter(p => p.idFamilia === f.idFamilia);
          familyProducts.forEach(p => {
            if (h.productos[p.key] === 2) {
              familyProductsState2Count++;
            }
          });

          if (h.productos[f.key] === 2) {
            total += parseFloat(h.adultos || 0) / (familyProductsState2Count || 1);
          } else {
            total += parseFloat(h.adultos || 0);
          }
        }
      });

      return Math.round(total);
    },
    toggleItem(h,producto){
      if (!h.productos) this.$set(h,'productos', {})
      
      this.$set(h.productos,producto.key,(h.productos[producto.key] ? (h.productos[producto.key]+1)%3 : 1))

      if (!this.cambios.includes(h.idEvento)) this.cambios.push(h.idEvento)
      this.$forceUpdate()
    },
    async savePlan(){
      const confirmation =
        await this.$refs.confirm.open(
          "Guardar los cambios",
          "¿Estás seguro de que quieres guardar los cambios?",
          { color: "primary" }
        );
      if (!confirmation) return;
      try {
        this.loading = true;
        await Promise.all([
          axios({
            method: "post",
            url: `/tapas`,
            data: this.huecos.filter(mod => this.cambios.includes(mod.idEvento)),
          }),
        ]);
        this.loading = false;
        this.cambios = []
        this.$root.$emit("snack", "Se han guardado los cambios correctamente");
      } catch (err) {
        this.loading = false;
        console.error(err);
        this.$root.$emit("snack", "No se han podido guardar los cambios");
      }
    },
    async getHuecos() {
      const { data } = await axios({
        url: `/tapas`,
      });
      this.huecos = data;
      this.huecos.forEach(h => {
        if (!h.productos) this.$set(h,'productos', {})
        if (!h.notas) this.$set(h,'notas', {})
      });
    },
    exportar() {
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet('PLATOS Y TAPAS');

      var cols = [{header:'EVENTOS', width:11},'','',{width:3, header:'ADULTOS'},{width:3, header:'CLÁSICA'},{width:3, header:'INFORMAL'},{width:3, header:'PB PTES'}]
      this.productos.forEach((f,i) => {
        if(i == 0 || f.superFamilia != this.productos[i-1].superFamilia) cols.push({ width:3, header: this.superFamilias.find(fam => fam.id == f.superFamilia).nombre, key:"superFamilia"})
        if(i == 0 || f.idFamilia != this.productos[i-1].idFamilia) cols.push({ width:3, header: this.familias.find(fam => fam.idFamilia == f.idFamilia).nombreFamilia, key:"familia"})
        cols.push({ width:3, header: f.nombreProducto})
      });

      sheet.columns = cols
      
      var tot = ["TOTAL",'','',this.huecos.reduce((acc,sum) => acc + (sum.adultos||0),0),this.huecos.filter(v => v.tipo == 1).length,this.huecos.filter(v => v.tipo == 2).length,this.huecos.filter(v => !v.tipo).length]
      this.productos.forEach((f,i) => {
        if(i == 0 || f.superFamilia != this.productos[i-1].superFamilia) {
          tot.push('')
        }
        if(i == 0 || f.idFamilia != this.productos[i-1].idFamilia) {
          tot.push('')
        }
        tot.push(this.calculateTotalForProduct(f))
      });
      sheet.addRow(tot)

      this.huecos.forEach(hueco => {
        var row = [
          new Date(hueco.fecha).toLocaleDateString('esp',{day:'numeric',month:'short'}).toUpperCase(),
          new Date(hueco.fecha).toLocaleDateString('esp',{year:'numeric'}).toUpperCase(),
          hueco.espacio || 'PTE',
          hueco.adultos || 0,
          +(hueco.tipo == 1),
          +(hueco.tipo == 2),
          +!hueco.tipo
        ]
      
        this.productos.forEach((f,i) => {
          if(i == 0 || f.superFamilia != this.productos[i-1].superFamilia) row.push('')
          if(i == 0 || f.idFamilia != this.productos[i-1].idFamilia) row.push('')
          if (hueco.productos[f.key]) row.push(hueco.adultos / hueco.productos[f.key])
          else row.push(0)
        });

        sheet.addRow(row)
      });
      
      sheet.eachRow({ includeEmpty: true }, (row) => {
        row.eachCell({ includeEmpty: true }, (cell) => {
          cell.alignment = { horizontal: 'center' };
        });
      });
      sheet.getRow(1).eachCell((cell,i) => {
        if (i > 3) cell.alignment = { textRotation: 90 , horizontal:'center'};
      });
      
      sheet.eachRow({ includeEmpty: true }, (row) => {
        var round = 0
        for (let i = 0; i < this.productos.length; i++) {
          if(this.productos[i].superFamilia != this.productos[i-1]?.superFamilia) {
            row.getCell(i+8+round).fill = {
              type:"pattern",
              pattern: 'solid',
              fgColor: { argb : "ff36a2eb" }
            }
            row.getCell(i+9+round).fill = {
              type:"pattern",
              pattern: 'solid',
              fgColor: { argb : "ffd39652" }
            }
            round+=2
          }
          else if(this.productos[i].idFamilia != this.productos[i-1]?.idFamilia) {
            row.getCell(i+8+round).fill = {
              type:"pattern",
              pattern: 'solid',
              fgColor: { argb : "ffd39652" }
            }
            round++
          }
        }
      })
      this.huecos.forEach((hueco,iH) => {
        var cell = 7
        for (let i = 0; i < this.productos.length; i++) {
          if(this.productos[i].superFamilia != this.productos[i-1]?.superFamilia) {
            cell+=3
          }
          else if(this.productos[i].idFamilia != this.productos[i-1]?.idFamilia) {
            cell+=2
          }
          else{
            cell++
          }
          if (hueco.notas[this.productos[i].key]) sheet.getRow(3+iH).getCell(cell).note = hueco.notas[this.productos[i].key]
        }
      });
      sheet.mergeCells(1,1,1,3)
      sheet.mergeCells(2,1,2,3)

      sheet.getRow(2).height = sheet.getRow(2).height*2
      sheet.getRow(2).eachCell(c => {
        c.font = {
          size: 14,
          bold: true
        }
      })
      sheet.getColumn(7).eachCell(c => {
        if (c.value == 1) c.font = {color: {argb: "ffff0000"}}
      })
      workbook.xlsx.writeBuffer()
      .then(buffer => FileSaver.saveAs(new Blob([buffer]), `${"PLATOS Y TAPAS - EXPORTADO EL " + (new Date().toLocaleDateString('default', { weekday: 'short' }).toUpperCase() + ' - ' + (('0' + new Date().getDate()).slice(-2)) + (new Date().toLocaleString('default', { month: 'short' }).toUpperCase())) + " - " + new Date().getFullYear()}.xlsx`))
      .catch(err => console.log('Error writing excel export', err))
    },
  },
  async mounted() {
    this.loading = true
    await Promise.all([
      this.getHuecos()
    ])
    this.loading = false
  },
};
</script>

<style scoped>
.stick {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
}
.diagonal-header {
  margin: 0;
  max-width: 48px !important;
  height: 165px !important;
  white-space: nowrap;
  vertical-align: bottom;
  text-align: left;
  overflow: visible;
}
.diagonal-text {
  transform: rotate(-90deg);
  transform-origin: center center;
  text-align: left;
  white-space: nowrap;
}
.diagonal-familia {
  max-height: 30px;
  transform: rotate(-90deg);
  transform-origin: center center;
  text-align: left;
  white-space: nowrap;
}
.item:hover{
  z-index:1000;
  border: none !important;
  -webkit-box-shadow: inset 0px 0px 5px 1px rgba(0,0,0,0.75) !important;
  -moz-box-shadow: inset 0px 0px 5px 1px rgba(0,0,0,0.75) !important;
  box-shadow: inset 0px 0px 5px 1px rgba(0,0,0,0.75) !important;
  cursor:pointer
}
.itemDark:hover{
  z-index:1000;
  border: none !important;
  -webkit-box-shadow: inset 0px 0px 5px 1px rgba(255,255,255,0.75) !important;
  -moz-box-shadow: inset 0px 0px 5px 1px rgba(255,255,255,0.75) !important;
  box-shadow: inset 0px 0px 5px 1px rgba(255,255,255,0.75) !important;
  cursor:pointer
}
.v-input--checkbox{
  margin-top: 0 !important;
}
.v-input--checkbox >>> .v-input--selection-controls__input{
  margin: 0 !important;
}
tbody tr:hover {
  background-color: transparent !important;
}
/* .diagonal-header::before {
  display: block;
  transform: rotate(45deg);
  transform-origin: right bottom;
  content:'';
  width: 0px !important;
  height: 100px !important;
  border: 1px solid black; 
} */
</style>

<style>
.v-data-table__wrapper{
  overflow-x: auto !important;
  overflow-y: auto !important;
  height: 99vh !important;
}

.v-data-table__wrapper thead{
  position: sticky;
  top: 0;
  z-index: 10;
}
</style>